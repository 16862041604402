@import url('https://fonts.googleapis.com/css2?family=Anybody&family=Quicksand:wght@300&family=Sevillana&display=swap');

.navbar {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  box-shadow: 0px 2px 10px #80a892;
  background-color: #94BFA7;
  height: 80px;
  color: white;
  font-size: 30px;
}

.title {
  display: flex;
  font-weight: bold;
  font-family: "Sevillana";
  align-items: center;
  height: 100%;
  margin-left: 10px;
}

.links {
  font-size: 17px;
  color: white;
  margin-left: 20px;
}

.links a {
  background-color: #035E7B;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  padding: 7px;
  margin-left: 5px;
}

