.boxes {
  display: flex;
  justify-content: center;
}
.sensor-box {
  background-color: transparent;
  border-radius: 10px;
  outline-style: groove;
  outline-color: #035d7b76;
  width: 45%;
  height: 500px;
  padding-top: 20px;
  margin: 10px;
  font-size: 20px;
  color: #035E7B;
}

.box-title {
  border: 1px solid red;
}

