@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');

body {
  margin: 0;
  font-family: "Quicksand";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

/* FONT ONLY
body, input, textarea, button {
  font-family: Quicksand;
} */

